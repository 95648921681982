function carousel() {
    const carousel = document.querySelector('.js-carousel')
    const slides = document.querySelectorAll('.js-slide')
    const totalSlides = slides.length

    if (!carousel || !totalSlides) {
        return null

    }
    const prevBtn = document.querySelector('.js-prev-btn')
    const nextBtn = document.querySelector('.js-next-btn')

    // Update button states based on current slide index
    function updateButtonStates() {
        prevBtn.disabled = currentSlideIndex === 0
        nextBtn.disabled = currentSlideIndex === totalSlides - 1
    }

    function updateThumbnails() {
        const totalThumbnails = document.querySelectorAll('.js-thumbnail')

        if (!totalThumbnails.length) return null

        totalThumbnails.forEach((thumbnail, index) => {
            if (index === currentSlideIndex) {
                thumbnail.classList.add('border-primary')
            } else {
                thumbnail.classList.remove('border-primary')
            }
        })
    }

    let currentSlideIndex = 0
    const slideWidth = carousel.querySelector('.js-slide').offsetWidth // Get the width of a single slide
    const gap = parseFloat(getComputedStyle(document.querySelector('.js-carousel')).gap)

    if(prevBtn){
        prevBtn.addEventListener('click', () => {
            if (currentSlideIndex > 0) {
                currentSlideIndex--
                slides[currentSlideIndex].scrollIntoView({behavior: "smooth", block: "nearest"})
            }
        })
    }

    if(nextBtn){
        nextBtn.addEventListener('click', () => {
            if (currentSlideIndex < totalSlides - 1) {
                currentSlideIndex++
                slides[currentSlideIndex].scrollIntoView({behavior: "smooth", block: "nearest"})
            }
        })
    }

    // Update state upon scrolling the carousel
    carousel.addEventListener('scroll', () => {
        currentSlideIndex = Math.round(carousel.scrollLeft / slideWidth)
        updateButtonStates()
        updateThumbnails()
    })

    //Update on mount, in case of caching
    currentSlideIndex = Math.round(carousel.scrollLeft / slideWidth)
    updateButtonStates()
    updateThumbnails()
}
carousel()
